import React from "react";
import classnames from "classnames";
import AppLink from "../AppLink/AppLink";
import { getPerformanceYearRange, isItemLive, formatDate, getComposerLastName, isShortFormContent } from "../../utils";
import "./Tile.scss";

class BroadcastTile extends React.Component<any, any> {

  isPlayingInterval

  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      hideFromList: false
    }
  }

  componentDidMount(): void {
    this.isPlayingItem();
  }
  componentWillUnmount(): void {
    clearInterval(this.isPlayingInterval)
  }
  isPlayingItem = () => {
    const { id, startDate, activeItem } = this.props;
    this.isPlayingInterval = setInterval(() => {
      // console.log(id, activeItem.id)
      const isActiveItem = (activeItem && id === activeItem.id)
      if (isActiveItem) {
        // console.log("Active Item: ", this.state.isPlaying )
       if ( (startDate === activeItem.start) && isItemLive(activeItem)) {
        // clearInterval(this.isPlayingInterval)
        if (!this.state.isPlaying) {
          this.setState({isPlaying: true})
        }
       } else {
        if (this.state.isPlaying) {
          clearInterval(this.isPlayingInterval)
          this.setState({isPlaying: false, hideFromList: true})
        }
       }
      }
    }, 2000)
  }

  play = () => {
    const player = videojs.players.player;
    if (player) {
      player.play();
      // @ts-ignore
      document.getElementById("player").scrollIntoView({behavior: "smooth", block: "start"});
    }
  }

  render() {
    const { id, mediaType, header, resolutionType, imageurl, performanceDateISO, cast, parentPerformance, shortCast } = this.props;
    const performanceYearRange = getPerformanceYearRange(performanceDateISO);
    const composer = getComposerLastName(cast);
    const preTitle: any[] = [];
    if (composer) {
      preTitle.push(composer);
    }
    if (performanceDateISO) {
      preTitle.push(formatDate(performanceDateISO));
    }

    const tileMarkup = ( <div className={classnames("Tile", this.state.isPlaying ? `playing-now` : "", "BroadcastTile", isShortFormContent(mediaType) ? 'short-form' : '')} onClick={this.play}>
      <div className="Tile-header">
        {/* <span className="start-date">{formatDate(startDate)} | {getFormattedTime(startDate)}</span> */}
        <span className="playing-now-btn">Playing Now</span>
      </div>
      <div className={classnames("Tile-image-container", (mediaType === "audio" || mediaType === "audio_stream") ? `audio-${performanceYearRange}s` : '')}>
        { (mediaType !== "audio" && mediaType !== "audio_stream") && imageurl && <img src={imageurl} alt="Performance" /> }
        { (mediaType === "audio" ||  mediaType === "audio_stream") &&
          <div className="Tile-image-container-overlay">
            <p>Live Audio Recording</p>
            <p>{performanceYearRange}s</p>
          </div>
        }
        {resolutionType && resolutionType === "HD" && <span className="Tile-hd-marker">{resolutionType}</span>}
      </div>
      <div className="Tile-body">
        { !isShortFormContent(mediaType) &&
          <div className="Tile-body-metadata">
            { preTitle.length && <span className="Tile-body-pre-title">{preTitle.join(' | ')}</span> }
          </div>
        }
        <h2 className="Tile-body-header" dangerouslySetInnerHTML={{__html: header}} />
        { cast && !isShortFormContent(mediaType) &&
          <div className="Tile-body-cast">
            <div dangerouslySetInnerHTML={{__html: shortCast}}></div>
          </div>
        }
      </div>
    </div>)


      if ((mediaType === "audio_stream") && parentPerformance) {
        return <AppLink to={`/performance/detail/${parentPerformance.id}`} load={true}>{tileMarkup}</AppLink>
      } else {
        return <AppLink to={`/performance/detail/${id}`} load={true}>{tileMarkup}</AppLink>
      }
  }
}
export default BroadcastTile;
