import React from "react";
import Player from "../components/Player/Player";
import Grid from "../components/Grid/Grid";
import PerformanceInfo from "../components/PerformanceInfo/PerformanceInfo";
import ShortFormPerformanceInfo from "components/PerformanceInfo/ShortFormPerformanceInfo";
import { getTileInfo, childrenTileMapper, isShortFormContent } from "../utils";

enum Component {
  Player,
  PerformanceInfo,
  Grid
};

const getComponentProps = (performance) => {
  const components = [];

  const playerComponent = {
    type: 'player',
    info: getTileInfo(performance),
    performance,
    endScreenInfo: {
      header: 'You might also enjoy',
      tiles: (performance.relatedPerformances && performance.relatedPerformances.nodes.length > 0) ?
        childrenTileMapper(performance.relatedPerformances.nodes).slice(0, 3) : []
    }
  }

  const performanceInfoComponent = {
    type: 'performanceInfo',
    info: getTileInfo(performance)
  }

  const relatedPerformancesComponent = {
    type: 'grid',
    header: 'You might also enjoy',
    tiles: (performance.relatedPerformances && performance.relatedPerformances.nodes.length > 0) ?
    childrenTileMapper(performance.relatedPerformances.nodes).slice(0, 3) : []
  }

  components.push(playerComponent, performanceInfoComponent);

  if (relatedPerformancesComponent.tiles.length > 0) {
    components.push(relatedPerformancesComponent);
  }

  return components;
}

const getComponents = (performance) => {
  const mediaType = performance.mediaType && performance.mediaType.toLowerCase();
  const isShortFormAsset = isShortFormContent(mediaType);

  return getComponentProps(performance).map((fullProps, index) => {
    const { type, ...props } = fullProps;
    let component;
    switch (type) {
      case 'player':
        component =  <Player key={index} {...props} />;
        break;
      case 'performanceInfo':
        if (isShortFormAsset) {
          component =  <ShortFormPerformanceInfo key={index} {...props} />;
        } else {
          component =  <PerformanceInfo key={index} {...props} />;
        }
        break;
      case 'grid':
        component =  <Grid key={index} {...props} />;
        break;
    }
    return component;
  })
}

const getPageInfo = (performance) => {
  return {
    title: performance.name || "Met Opera On Demand",
    id: performance.id
  }
};

export default function parse(performance) {
  return {
    ...getPageInfo(performance),
    components: getComponents(performance)

  }
};

export { Component };
