import React from "react";
import Player from "../components/Player/Broadcast/Player";
import Grid from "../components/Grid/Grid";
import Carousel from "../components/Carousel/Carousel";
import Editorial from "../components/Editorial/Editorial";
import { getTileInfo, childrenTileMapper, upcomingItems } from "../utils";

enum Component {
  Player,
  Carousel,
  Grid,
  Editorial
};

const getComponentProps = (activeItemPerformance, components, activeItem, onPlayerCreated, tier?) => {
  const componentsArr = components.map((component, index) => {
    switch (component.type.toLowerCase()) {

      case "live":
        return {
          type: Component.Player,
          componentType: component.type,
          info: !_.isEmpty(activeItemPerformance) && getTileInfo(activeItemPerformance),
          activeItem,
          onPlayerCreated,
          tier,
        }

      case "schedule":
        const nonStationIdentifierItems = component.items.length > 0 ? upcomingItems(component.items).filter(item => !item.stationIdentifier) : [];

        if (nonStationIdentifierItems.length > 0) {
          return {
            type: Component.Carousel,
            componentType: component.type,
            header: 'Up Next',
            activeItem,
            tiles: childrenTileMapper(nonStationIdentifierItems).slice(0, 1)
          }
        } else {
          return null;
        }

      case "audio_stream_schedule":
        if (component.items.length > 0) {

          return {
            type: Component.Carousel,
            componentType: component.type,
            header: 'Audio Broadcast Schedule',
            activeItem,
            tiles: childrenTileMapper(component.items)
          }
        } else {
          return null;
        }

      case "editorial":
        return {
          type: Component.Editorial,
          componentType: component.type,
          html: component.html
        }

      default:
        return null;
    }
  });
  // Commenting out as it's not a requirement for Audio Streams at this time (and to save time). Can allocate time to "fix" this in the futre.
  // if (activeItemPerformance && activeItemPerformance.relatedPerformances && activeItemPerformance.relatedPerformances.nodes.length > 0) {
  //   const relatedPerformancesComponent = {
  //     type: Component.Grid,
  //     header: 'You might also enjoy',
  //     tiles: childrenTileMapper(activeItemPerformance.relatedPerformances.nodes).slice(0, 3)
  //   }
  //   componentsArr.push(relatedPerformancesComponent);
  // }
  return componentsArr;
}

const getComponents = (activeItemPerformance, components, activeItem, onPlayerCreated, tier) => {
  return getComponentProps(activeItemPerformance, components, activeItem, onPlayerCreated, tier).map((fullProps, index) => {
    const { type, ...props } = fullProps || {};
    let component;
    switch (type) {
      case Component.Player:
        component =  !_.isEmpty(activeItemPerformance) && <Player key={activeItemPerformance.id} tier {...props} />;
        break;
      case Component.Carousel:
        component =  <Carousel key={index} {...props} />;
        break;
      case Component.Editorial:
        component =  <Editorial key={index} {...props} />;
        break;
      case Component.Grid:
        component =  <Grid key={index} {...props} />;
        break;
    }
    return component;
  })
}

const getPageInfo = (activeItem) => {
  return {
    title: activeItem.title || "Met Opera On Demand",
    id: activeItem.id
  }
};

export default function parse(activeItemPerformance, components, activeItem, onPlayerCreated, tier) {
  return {
    ...getPageInfo(activeItemPerformance),
    components: getComponents(activeItemPerformance, components, activeItem, onPlayerCreated, tier)
  }
};

export { Component };
