import React from "react";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../../store";
import brightcovePlayerLoader from '@brightcove/player-loader';
import { Button } from "@brightcove/studio-components";
import { SVGImage } from "../../../assets/images";
import PerformanceInfo from "../../../components/PerformanceInfo/PerformanceInfo";
import ShortFormPerformanceInfo from "../../../components/PerformanceInfo/ShortFormPerformanceInfo";
import { formatDate, getComposerLastName, getPerformanceYearRange, isShortFormContent } from "../../../utils";
import classnames from "classnames";
import "../Player.scss";
import "./Player.scss";

function getCurrentMachineTime(delta?) {
  if (typeof delta !== 'number') {
    delta = 0;
  }
  return new Date().getTime() - delta;
}


class Player extends React.Component<any, any> {
  player;
  liveCheckInterval;


  constructor(props) {
    super(props);
    this.state = {
      hideMoreInfo: true,
      paused: true,
      isLive: true,
      hasStarted: false,
      activeTrack: null
    }
  }

  componentDidMount() {
    this.disposePlayer();
    this.brightcovePlayer();

    this.liveCheckInterval = setInterval(() => {
      if (this.isSessionLive()) {
        if (!this.state.hasStarted) this.setState({ hasStarted: true });
      } else {
        if (this.state.hasStarted) {
          this.disposePlayer();
          clearInterval(this.liveCheckInterval);
          this.setState({
            isLive: false,
            hasStarted: false,
          });
        }
      }
    }, 1000);
  }

  isSessionLive = () => {
    const { start, end } = this.props.activeItem;
    const currentTime = new Date().getTime(),
        startTime = new Date(start).getTime(),
        endTime = new Date(end).getTime();

    if (currentTime >= startTime && currentTime <= endTime) {
      return true;
    }
    return false;
  }

  toggleInfo = () => {
    this.setState((prev) => {
      return {hideMoreInfo: !prev.hideMoreInfo};
    });
  }

  brightcovePlayer = () => {
    return loadBrightcovePlayer.call(this, this.props);
  }

  disposePlayer = () => {
    if (this.player && this.player.el_) {
      const playerId = isShortFormContent(this.props.info.mediaType) ? process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_SHORT_FORM_BROADCAST : process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_LONG_FORM_BROADCAST;
      const playerStylesContainer = document.querySelector(`.bc-style-${playerId}-default`);

      brightcovePlayerLoader.reset();
      this.player.dispose();

      if (playerStylesContainer) {
        playerStylesContainer.remove();
      }
    }
  }

  
  togglePlay = () => {
    if (this.player) {
      const { store: { user }, tier, info: { mediaType } } = this.props;

      if(!user && (tier && tier.toLowerCase() !== 'free') && !isShortFormContent(mediaType)) {
        return;
      }

      const isPaused = this.player.paused();

      if (isPaused) {
        this.player.play();
      } else {
        this.player.pause();
      }

      this.setState({
        paused: !isPaused
      });
    }
  }

  componentWillUnmount() {
    this.disposePlayer();
    this.liveCheckInterval && clearInterval(this.liveCheckInterval);
    window.removeEventListener("blur", this.player.pause)
  }

  render() {
    const { hideMoreInfo, paused, isLive, hasStarted, activeTrack } = this.state;
    const { showLogIn, store: { user, dynamicStrings }, info: { id, cast, performanceDateISO, header, mediaType, imageurl }, tier, activeItem } = this.props;
    const showLoginOverlay = (!user && tier.toLowerCase() !== "free");
    const composerLastName = getComposerLastName(cast);
    const performanceYearRange = getPerformanceYearRange(performanceDateISO);
    const isBroadcastEnded = !isLive && activeItem.id === id;

    console.log("mediaType: ", mediaType)

    if (activeItem.id !== id) {
      return null;
    }

    return (
      <div className={`Player-wrapper ${mediaType}${isBroadcastEnded ? " hasEnded" : ""}`}>
        {(mediaType === "audio" || mediaType === "audio_stream") &&
          <div className="performance-info-wrapper">
            <div className={classnames("performance-image", `audio-${performanceYearRange}s`)}>
              <div className="performance-image-overlay">
                <p>Live Audio Recording</p>
                <p>{performanceYearRange}s</p>
              </div>
            </div>
            <div className={`performance-info ${showLoginOverlay ? "show-login-overlay" : ""} `}>
              <p className="performance-composer-date">
                <span>{composerLastName}</span>
                <span className="separator">|</span>
                <span>{formatDate(performanceDateISO, true)}</span>
              </p>
              { header && <h2 className="performance-title" dangerouslySetInnerHTML={{__html: header}} /> }
              { activeTrack &&
                <div className="performance-active-track">
                  <div className="performance-active-track-title">{activeTrack.title}</div>
                  <div className="performance-active-track-cast">{activeTrack.artists}</div>
                </div>
              }
              <div className="Player-controls">
                { isLive && hasStarted &&
                  <div className="play-pause-btn" onClick={this.togglePlay}>
                    <img src={paused ? SVGImage.PlayBtn : SVGImage.PauseBtn} alt={paused ? "Play Button" : "Pause Button"} />
                  </div>
                }
                {showLoginOverlay &&
                  <div className="subscribe-overlay">
                    <Button theme="classic" className="btn btn-pink" onClick={showLogIn}>Login / Subscribe to watch</Button>
                  </div>
                }{
                  // (mediaType !== "audio" && mediaType !== "audio_stream") &&
                  <div className="performance-info-toggle-cta">
                    <Button theme="classic" className="btn btn-transparent white" onClick={this.toggleInfo}>{hideMoreInfo ? <span>More Info</span> : <span> - Hide Info</span>}</Button>
                  </div>
                }
              </div>
            </div>
          </div>
        }
        { mediaType === "short_form_audio" &&
          <div className="performance-info-wrapper">
            <div className="performance-image">
              <img src={imageurl} alt="Performance" />
            </div>
            <div className="performance-info">
              { header && <h2 className="performance-title" dangerouslySetInnerHTML={{__html: header}} /> }
              <div className="Player-controls">
                { isLive && hasStarted &&
                  <div className="play-pause-btn" onClick={this.togglePlay}>
                    <img src={paused ? SVGImage.PlayBtn : SVGImage.PauseBtn} alt={paused ? "Play Button" : "Pause Button"} />
                  </div>
                }
                <div className="performance-info-toggle-cta">
                  <Button theme="classic" className="btn btn-transparent white" onClick={this.toggleInfo}>{hideMoreInfo ? <span>More Info</span> : <span> - Hide Info</span>}</Button>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="Player">
          {showLoginOverlay && !isShortFormContent(mediaType) &&
            <div className="subscribe-overlay">
              <Button theme="classic" className="btn btn-pink" onClick={showLogIn}>Login / Subscribe to watch</Button>
            </div>
          }
        </div>
        <div className="broadcast-ended-message">{dynamicStrings["broadcast_ended_message"]}</div>
        {!hideMoreInfo &&
          <>
            {isShortFormContent(mediaType) ?
              <ShortFormPerformanceInfo  {...this.props} /> : <PerformanceInfo {...this.props} />
            }
          </>
        }
      </div>
    )
  }
};

function loadBrightcovePlayer(this: Player, props) {
  console.log("loading bcove player")
  const { start, end } = this.props.activeItem || {};
  const { store: { user }, info: { extId, mediaType }, onPlayerCreated, tier } = this.props;
  const accountId = isShortFormContent(mediaType) ? process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID_SHORT_FORM : process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID_LONG_FORM;
  const playerId = isShortFormContent(mediaType) ? process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_SHORT_FORM_BROADCAST : process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_LONG_FORM_BROADCAST;

  const playerSettings = {
    refNode: ".Player",
    refNodeInsert: 'append',
    accountId: accountId,
    playerId: playerId,
    embedId: 'default',
    videoId: `ref:${extId}`,
    options: {
      id: 'player',
      playsinline: true
    },
    onEmbedCreated: function(embed){
      embed.removeAttribute("controls");
    }
  }

  return brightcovePlayerLoader(playerSettings).then((success) => {
    this.player = success.ref;
    onPlayerCreated(this.player);
    const player = this.player;
    let userTier = tier && tier.toLowerCase();


    if ('mediaSession' in navigator) {
      const pausePlayer = ()=> {
        this.setState({
          paused: true
        });
      }
        navigator.mediaSession.setActionHandler('play', pausePlayer);
        navigator.mediaSession.setActionHandler('pause', pausePlayer);
        navigator.mediaSession.setActionHandler('seekbackward', pausePlayer);
        navigator.mediaSession.setActionHandler('seekforward', pausePlayer);
      
    }


    this.player.MetBroadcastPlayer({
      user,
      startTime: start,
      endTime: end
    });
    console.log("PLAYER: ", this.player)
    document.addEventListener('visibilitychange', () => {
      player.pause()
    })
    
    this.player.on("play", () => {
      if (!user && userTier !== "free" && !isShortFormContent(mediaType)) {
        this.player.pause();
      } else {
        this.setState({
          paused: this.player.paused()
        });
      }
    });

    this.player.on("pause", () => {
      this.setState({
        paused: true
      });
    });

    const getTrackInfo = (cue) => {
      const metadata = cue && cue.originalCuePoint && cue.originalCuePoint.metadata;
      if (metadata) {
        const [title, artists] = metadata.split('|');
        return {
          title: title.replace('title=', ''),
          artists: artists.replace('artists=', '')
        }
      }
      return {};
    }

    this.player.on("loadstart", () => {
      if (!user && userTier !== "free") {
        this.player.addClass('vjs-login-overlay-showing');
      }

      const textTracks = this.player.textTracks();
      if (textTracks.length) {
        textTracks[0].oncuechange = () => {
          const activeCue = textTracks[0].activeCues[0];
          const activeTrack = getTrackInfo(activeCue);
          this.setState({
            activeTrack
          });
        }
      }
    });

    this.player.on("timeupdate", () => {
      const currentTime = getCurrentMachineTime();
      const player = this.player;
      const TOLERANCE = 1000;

      if (Math.abs((currentTime - (player._lastCheckTimeUpdate || 0))) > TOLERANCE / 10) {

        const deltaTime = currentTime - start;

        // if we drift more than tolerance or the end time of this item has past,
        // force the re-evaluation of the player
        if ((currentTime > end ||  Math.abs(deltaTime - (currentTime * 1000)) > TOLERANCE)) {
          const currentTime = new Date().getTime(),
          startTime = new Date(options.startTime).getTime(),
          endTime = new Date(options.endTime).getTime(),
          seekToSeconds = (currentTime - startTime) / 1000;
          if (currentTime < endTime) {
            player.currentTime(Math.round(seekToSeconds));
          }
          // this.player.currentTime()
        }

        this.player._lastCheckTimeUpdate = currentTime;
      }
    })

    this.player.on("ended", () => {
      if (!this.isSessionLive()) {
        this.setState({
          isLive: false,
          hasStarted: false,
        });

        this.disposePlayer();
      }
    });

  }).catch((err) => {
    this.setState({
      error: true,
      errorMsg: 'Player failed to load.'
    })
    console.error(err);
  });
}

export default compose(withStore)(Player)
