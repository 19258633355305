import React from "react";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import "./PageNotFound.scss";



const BeforeYouProceed: React.FunctionComponent<any> = ({ store: { language }, history }) => {
  return (
    <div className="PageNotFound">
        <h2>Page Not Found</h2>
        <div className="rich-text">
          <p>This page is not available. Please try a different page.</p>
        </div>
    </div>
  )
};

export default compose(withStore)(BeforeYouProceed);
