import React from "react";
import { Button } from "@brightcove/studio-components";
import { SVG } from "../../assets/images";
import ExternalLink from "../ExternalLink/ExternalLink";
import PerformanceActions from "../PerformanceActions/PerformanceActions";
import classnames from "classnames";
import { formatDate, getCastByType, formatDuration, getPageType, toggleAccordion, fetchHandler, getClientApiURL, getComposerLastName, getCastAndCrew } from "../../utils";
import "./PerformanceInfo.scss";

const LinkItem: React.FunctionComponent<any> = ({ url, text, image, alt, target, children, style}) => {
  return <ExternalLink url={url} text={text} image={image} alt={alt}  style={style}  target={target} children={children} />
};


class PerformanceInfo extends React.Component<any,any> {

  constructor(props) {
    super(props);
    this.state = {
      hideMoreInfo: false,
      inFavorites: props.info && props.info.isFavorite
    }
  }

  toggleInfo = () => {
    this.setState((prev) => {
      return {hideMoreInfo: !prev.hideMoreInfo};
    });
  }

  toggleFavorite = () => {
    const contentId = this.props.info.id;
    const url = getClientApiURL("/user/favorite");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "contentId": contentId
      })
    }
    if (!this.state.inFavorites) {
      fetchHandler(url, options, (data) => {
        this.setState({
          "inFavorites": true
        });
      });
    } else {
      const options = {
        method: "DELETE"
      };
      fetchHandler(`${url}/${contentId}`, options, () => {
        this.setState({
          "inFavorites": false
        });
      });
    }
  }


  render() {
    const { hideMoreInfo, inFavorites } = this.state;
    const {
      header,
      fullSynopsis,
      cast,
      performanceDate,
      performanceDateISO,
      runTime,
      fullSynopsisUrl,
      ticketURL,
    } = this.props.info;
    const composer = (cast && getCastByType(cast, "composer", true)) || "";
    const composerLastName = getComposerLastName(cast);
    const librettist = (cast && getCastByType(cast, "librettist", true)) || "";
    const group = (cast && getCastByType(cast, "group", true)) || "";
    const pageType = getPageType();
    const isBroadcastPage = (pageType === "broadcast");
    const castAndCrew = getCastAndCrew(cast);
    const formattedPerformanceDate = (performanceDate || performanceDateISO) && formatDate(performanceDate || performanceDateISO);

    return (
      <div className={classnames("PerformanceInfo", pageType)}>
        <div className="performance-header">
          <div className="performance-header-info">
            { composerLastName && <p className="performance-composer">{composerLastName}</p> }
            { header && <h1 className="performance-title">{header}</h1> }
          </div>
          {/* {isBroadcastPage && mediaType !== "audio" &&
            <div className="performance-header-cta">
              <Button theme="classic" className="btn btn-transparent white" onClick={this.toggleInfo}>{hideMoreInfo ? <span>More Info</span> : <span> - Hide Info</span>}</Button>
            </div>
          } */}
          {!isBroadcastPage &&
            <div className="performance-header-cta">
              <LinkItem url={ticketURL || "https://www.metopera.org/calendar"} style={{textDecoration:"none"}}>
                 <button className="btn btn-transparent white" style={{cursor: "pointer"}}><span>Buy Tickets<SVG.Calendar/></span></button>
              </LinkItem>
            </div>
          }
        </div>
        {!hideMoreInfo &&
          <React.Fragment>
            <div className="performance-body">
              <PerformanceActions showFavoriteAction={true} inFavorites={inFavorites} onToggleFavorite={this.toggleFavorite} />
              <div className="performance-description">{fullSynopsis}</div>
              <div className="performance-full-synopsis-url">
                <Button theme="classic" Component="a" componentProps={{href: fullSynopsisUrl, target: "_blank"}} className="btn btn-transparent white">Full Synopsis</Button>
              </div>
              <div className="performance-metadata accordion">
                <div className="performance-metadata-header accordion-header" onClick={toggleAccordion}>
                  <h2>Performance Info</h2>
                  <div className="accordion-button"><span></span></div>
                </div>
                <div className="performance-metadata-body accordion-body">
                  <PerformanceActions showFavoriteAction={true} inFavorites={inFavorites} onToggleFavorite={this.toggleFavorite} />
                  { formattedPerformanceDate && <p className="performance-date"><span>Performance Date</span><span>{formattedPerformanceDate}</span></p> }
                  { composer && <p className="performance-composer"><span>Composer</span><span>{composer}</span></p> }
                  { librettist && <p className="performance-librettist"><span>Librettist</span><span>{librettist}</span></p> }
                  { runTime && <p className="performance-librettist"><span>Run Time</span><span>{formatDuration(runTime)}</span></p> }
                  { group && <p className="performance-group-name">{group}</p> }
                </div>
              </div>
            </div>
            <div className="performance-body-cta">
              <Button theme="classic" Component="a" componentProps={{href: fullSynopsisUrl, target: "_blank"}} className="btn btn-transparent white btn-full-synopsis-url">Full Synopsis</Button>
              { isBroadcastPage && <Button theme="classic" Component="a" componentProps={{href: ticketURL || "https://www.metopera.org/calendar", target: "_blank"}} className="btn btn-pink">Buy Tickets</Button> }
            </div>
            {cast && castAndCrew.length > 0 &&
              <div className="performance-cast accordion">
                <div className="performance-cast-header accordion-header" onClick={toggleAccordion}>
                  <h2>Cast & Creative</h2>
                  <div className="accordion-button"><span></span></div>
                </div>
                <ul className="performance-cast-body accordion-body">
                  {
                    castAndCrew.map((person, index) => {
                      const role = person.role && person.role.toLowerCase();
                      return <li key={index}><span>{role === "artist" && person.roleName ? person.roleName : person.role}</span><span>{person.person.name}</span></li>
                    })
                  }
                </ul>
              </div>
            }
          </React.Fragment>
        }
      </div>
    )
  }
}

export default PerformanceInfo;
